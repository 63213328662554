import axios from 'axios';
import EventBus from '../../utils/EventBus';
import Config from '../utils/config';

export default {
    props: ['id', 'label', 'nominee', 'voted', 'authenticated'],

    template: `
            <button v-if="!hasVoted" @click="handleVote" class="btn btn--default btn--upper btn--full-width">{{ label }}</button>
            <button v-else @click="handleVoted" class="btn btn--grey btn--upper btn--full-width">Abgestimmt</button>
    `,

    methods: {
        vote() {
            axios.post(`/vote/${this.id}/nominee/${this.nominee}`).then((res) => {
                EventBus.$emit('has-voted');
            }).catch(err => console.log(err));
        },

        handleVoted() {
            EventBus.$emit('toggle-modal');
        },

        handleVote() {
            //check whether the profile is completed or not if not display profile wizard
            if ($("#modal").length) {
                $('#modal').addClass('modal__container--opend').removeClass('modal__container--closed');
                return;
            } // end
			
            if (!!this.authenticated && !this.hasVoted) {
                this.vote();
            }

            this.handleVoted();
        },
    },

    data() {
        return {
            hasVoted: !!this.voted && !!this.authenticated
        }
    },

    created() {
        EventBus.$on('has-voted', () => {
            this.hasVoted = true;
            Config.voted = true;
        });
    },
}