import axios from 'axios';
import EventBus from '../utils/EventBus';

export default {
    template: `
        <div v-if="visible" id="modal" class="modal__container modal__container--opend">
            <section class="modal">
                <div class="modal__close" @click="toggle">
                   Fenster schließen <i class="fa fa-times" aria-hidden="true"></i>
                </div>
                <slot v-if="visible" class="modal__content"></slot>
            </section>
        </div>
    `,

    methods: {
        toggle() {
            this.visible = !this.visible;


            if (this.visible) {
                $('body').on('click', '.remember-vote', (evt) => {
                        axios.post(`/vote/remember`).then((res) => {
                            window.location.reload(true);
                        }).catch(err => console.log(err));
                    }
                );
                $('body').on('click', '.not-remember-vote', (evt) => {
                        axios.post(`/vote/notremember`).then((res) => {
                            window.location.reload(true);
                        }).catch(err => console.log(err));
                    }
                );

            }

            if (this.visible) {
                $('body').on('click', '.modal__container', (evt) => {
                        if (evt.currentTarget.classList.contains('modal__container')) {
                            this.visible = false;
                        }
                    }
                );
            }
        },
    },

    data() {
        return {
            visible: false
        }
    },

    created() {
        EventBus.$on('toggle-modal', () => {
            this.toggle();
        });
    },
}