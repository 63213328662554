import Vue from 'vue/dist/vue';
import VoteButton from './components/vote-button';
import PopupContent from './components/popup-content';
import Popup from '../components/popup';

Vue.component('vote-button', VoteButton);
Vue.component('popup', Popup);
Vue.component('popup-vote-content', PopupContent);

const voteApp = new Vue({
    el: '#vote-app'
});

export default voteApp;