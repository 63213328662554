import EventBus from '../utils/EventBus';
import Config from '../utils/config';

export default {
    props   : ['remember', 'hasvoted', 'authorized'],
    template: `	   
        <div v-if="!authorized" v-html="contents.notAuthorized"></div>
        <div v-else-if="remember" v-html="contents.remember"></div>
        <div v-else-if="voted" v-html="contents.hasVoted"></div>
        <div v-else v-html="contents.success"></div>		
    `,
    computed: {
        contents() {
            return {
                notAuthorized: `
                    <div id="wizard_info" class="rgst-box-inner">
                        <h2>
                            Stimme für deinen Verein
                        </h2>
                        <div class="modal__info">
                            <p>
                                Leider können nur angemeldete Personen an der Abstimmung teilnehmen.
                                Bitte melde Dich mit deinen Benutzerdaten an oder lege Dir in nur 2 Minuten ein neues
                                Benutzerkonto
                                an.
                            </p>
                            <p>Du kannst deinem Verein täglich eine Stimme geben.</p>
                        </div>
                        <div class="modal__btns">
                            <a href="/login" class="btn btn-default finden-btn">
                                Anmelden
                            </a>
                            <a href="/register" class="btn btn-default finden-btn">
                                Registrieren
                            </a>
                        </div>
                    </div>
                `,
                remember     : `
                    <div id="wizard_info" class="rgst-box-inner">
                        <h2>
                            Möchtest Du daran erinnert werden, abzustimmen?
                        </h2>
                        <div class="modal__info">
                            <p>
                                Vielen Dank für Deine Stimme. Aber für den Verein ist es besonders wichtig, dass die Unterstützer jeden Tag abstimmen. Möchtest Du, dass wir Dich jeden Tag bis Ende des Abstimmzeitraums per E-Mail daran erinnern, für Deinen Verein abzustimmen?

                            </p>

                        </div>
                        <div class="modal__btns">
                            <a @click.prevent="toggle" class="remember-vote btn btn-default finden-btn">                 
                                Ja, super Idee 
                            </a>
                            <a @click.prevent="toggle" class="not-remember-vote btn btn-default finden-btn">                 
                                Nein, bitte nicht 
                            </a>
                            
                        </div>
                    </div>
                `,
                hasVoted     : `
                    <div id="wizard_info" class="rgst-box-inner">
                        <h2>
                            Deine Stimme zählt bereits!
                        </h2>
                        <div class="modal__info">
                            <p>
                                Vielen Dank! Deine Stimme hilft bereits kräftig dabei euren Verein
                                hoffentlich bald "Verein des Monats" nennen zu können.
                            </p>
                            <p>
                                Du kannst deinem Verein täglich eine Stimme geben. <br> 
                                Bis morgen und viel Glück!
                            </p>
                        </div>
                        <div class="modal__btns">
                            <a @click.prevent="toggle" class="btn btn-default finden-btn">
                                Bis morgen!
                            </a>
                        </div>
                    </div>
                `,
                success      : `
                    <div id="wizard_info" class="rgst-box-inner">
                        <h2>
                            Deine Stimme zählt!
                        </h2>
                        <div class="modal__info">
                            <p>
                                Vielen Dank für deine Stimme! Du kannst morgen wieder abstimmen und bis
                                Ende des Monats jeden Tag eine
                                weitere Stimme für deinen Favoriten vergeben
                            </p>
                        </div>
                        <div class="modal__btns">
                            <a @click.prevent="toggle" class="btn btn-default finden-btn">
                                Ok
                            </a>
						
                        </div>
                    </div>
                `
            };
        },
        voted() {
            return this.hasvoted || Config.voted
        }
    },
    methods : {
        toggle() {
            EventBus.$emit('toggle-modal');
        }
    },
    created() {
        EventBus.$on('has-voted', () => Config.voted = true);
    }
}